<template>
   <v-app>
       <SingleAppBar title="จัดการหน่วยบริการ" :backward="true" name="setting"/>
      <v-main >
        <v-container color="transparent">
            <v-row  class="pt-10" v-if="$auth.allSite.length === 0">
               <v-col cols="12">
             <v-card color="transparent" elevation="0">
          <v-card-text class="text-center">
            <div class="pt-10">
            <v-avatar size="100" >
              <v-img :src="require('@/assets/siteempty.svg')"></v-img>
            </v-avatar>
            </div>
            <div class="pt-5 text-h5">คุณยังไม่มีสถานพยาบาล</div>
          </v-card-text>
        </v-card>
               </v-col>
            </v-row>
          <v-row v-else  class="pt-10">
             <v-col cols="12">
             <label class="text-title-3">หน่วยบริการ (โรงพยาบาล / ห้องพยาบาล)</label>
             </v-col>
            <v-col cols="12">
              <v-list >
      <v-list-item-group
        v-model="selectedTenant"
        active-class="primary--text"
        multiple
      >
        <template v-for="(item,index) in $auth.allSite">
          <v-list-item :key="item.siteId">
            <template v-slot:default="{ }">
               <v-list-item-icon>
          <v-icon color="primary">
            mdi-hospital-building
          </v-icon>
        </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title  class="text-body-large text-wrap" v-text="item.siteName"></v-list-item-title>
              </v-list-item-content>
                  <v-list-item-action @click="getSiteContent(item)">
                <v-icon
                  color="red"
                >
                $vuetify.icons.policy
                </v-icon>

  
              </v-list-item-action>

              <v-list-item-action @click="deleteServiceConfirm(item)">
        
                <v-icon
                  color="red"
                >
                  mdi-delete
                </v-icon>

  
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider :key="index"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

            </v-col>
          </v-row>
        </v-container>
           <v-dialog v-model="dialog" persistent>
        <ConfirmCard :title="'คุณต้องการลบรายการนี้หรือไม่'" :subtitle="'หากยืนยันการลบแล้วจะไม่สามารถย้อนกลับได้อีก'" @onClose="dialog=false" @onConfirm="deleteService"/>
      </v-dialog>
       <SiteContentCard :service="service" :content="content"/>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
    <v-footer height="100">
        <v-btn large  block color="primary" class="text-title-2" :to="{name: 'qrscan'}"><v-icon>mdi-qrcode-scan</v-icon>เพิ่มสถานพยาบาล</v-btn>
    </v-footer>
   </v-app>
</template>

<script>
// import Select from '@/components/common/Select'
import SingleAppBar from "@/components/common/SingleAppBar";
import ConfirmCard from "@/components/common/ConfirmDeleteCard";
import {deleteService, getSiteContent} from "@/api/"
import SiteContentCard from '@/components/sitecontent/SiteContentCard'

export default {
  components: {
   // Select,
    SingleAppBar,
    ConfirmCard,
    SiteContentCard
  },
  data () {
    return {
      data: '',
      loading: false,
      agree: false,
      selectedTenant: null,
      dialog: false,
      currentItem: null,
      items: [],
      content: [],
      service: null
    }
  },
  methods: {
    async deleteService() {
      this.dialog=false
      this.loading = true
      try {
      const ret = await deleteService({tenantId: this.currentItem.tenantId,siteId: this.currentItem.siteId})
      if(ret.data.code === 1) {
        this.$auth.allSite.splice(this.$auth.allSite.indexOf(this.$auth.allSite.filter(item=> item.siteId === this.currentItem.siteId)[0]),1)
      }
      }catch (error) {
        console.log(error)
      }finally{
        this.loading =false
      }
    },
    getSiteContent(item){
      console.log(item)
       getSiteContent({ tenantId: item.tenantId, siteId: item.siteId }, message => {
                 if (Array.isArray(message.data.result) && message.data.result.length > 0) {
                   console.log(message.data.result)
                   message.data.result.forEach(function (element) { 
                     element.dialog = true
                     element.process = false
                     element.tenantId = item.tenantId
                     element.siteId = item.siteId
                     element.mode = 'view'
                     const filtered = item.consent.privacyPolicy.filter(obj=>obj.contentId === element.id)
                     if(filtered.length> 0) element.isAccept =  filtered[0].isAccept

                     if(item.consent.termCondition.contentId === element.id) {
                       element.isAccept =  item.consent.termCondition.isAccept
                     }
                     
                    })
                    console.log(message.data.result)
                   this.service = item
                   this.content = message.data.result
                 }
              }, error => {
                console.log(error)
              })
    },
    deleteServiceConfirm(item) {
      this.currentItem = item
      this.dialog=true
    },
    fetchData () {
      this.loading = true
      this.$auth.allSite
      this.loading = false
    },
    setTenant () {
      this.$offlineStorage.set("site",this.selectedSite);
      this.$router.replace({name: 'main'});
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>
