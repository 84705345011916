<template>
 <v-app>
    <SingleAppBar title="เกี่ยวกับเรา" :backward="true" name="setting"/>
    <v-main class="fill-height">
      <v-container fluid class="fill-height">
       <v-row>
         <v-col cols="12">
           <v-card>
             <v-card-text class="text-center">
               <div class="text-h5 primary--text">
               Nexter Living Co., Ltd.
               </div>
               <div class="text-subtitle-1">
1 Siam cement Road, Bangseu Bangkok 10800
vwellapp@gmail.com
               </div>
             </v-card-text>
           </v-card>
         </v-col>
       </v-row>
      </v-container>
   
    </v-main>

 </v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";

export default {
  name: "Home",
  components: {
    SingleAppBar,
  },
};
</script>
